import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Banner from './banner'

import logo from '../images/circle-logo.svg'
import '../css/navbar.css'

const BANNER_MESSAGES = [
  {
    startDate: new Date('November 18, 2024'),
    endDate: new Date('December 1, 2024'),
    content: `
  <p style="text-wrap: balance;">
    Closed 11/25 - 11/30. Join us for Open Jump $10/child.<br/>
    DeForest: 11/29 10-12pm & 6:30-8:30pm; 11/30 12-2pm<br/>
    Cottage Grove: 11/29 & 11/30 10-12pm
  </p>`,
  },
  // {
  //   startDate: new Date('December 1, 2024'),
  //   endDate: new Date('January 5, 2025'),
  //   content: `
  // <p style="text-wrap: balance;">
  //   🎄 No classes December 23rd - January 4th. Happy Holidays! 🎄
  // </p>
  // <p style="text-wrap: balance;">
  //   Please join us for open jumps over the breaks!
  // </p>`,
  // },
]

export default class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuExpanded: false,
    }
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      menuExpanded: !prevState.menuExpanded,
    }))
  }

  bannerMsg = () => {
    const now = new Date().getTime()
    const matchingMsg = BANNER_MESSAGES.find(msg => {
      return (
        (!msg.startDate || msg.startDate.getTime() <= now) &&
        msg.endDate.getTime() >= now
      )
    })

    return matchingMsg ? (
      <div className="flex flex-wrap">
        <div
          className="mx-auto"
          dangerouslySetInnerHTML={{ __html: matchingMsg.content }}
        ></div>
      </div>
    ) : (
      undefined
    )
  }

  render() {
    return (
      <>
        {this.bannerMsg() && <Banner>{this.bannerMsg()}</Banner>}
        <div className={`shadow w-full bg-white z-40 relative`}>
          <div className="container mx-auto relative">
            <nav className="lg:flex items-center justify-between bg-white py-10 lg:py-6">
              <div className="navbar-logo-container flex flex-no-shrink mr-16">
                <Link to="/">
                  <img className="navbar-logo" src={logo} alt="Logo" />
                </Link>
              </div>
              <div
                className={`${
                  this.state.menuExpanded ? '' : 'hidden'
                } font-semibold uppercase no-underline w-full ml-4 mt-32 lg:ml-0 lg:mt-0 lg:flex lg:justify-between`}
              >
                <Link
                  to="/classes"
                  className="flex mt-8 text-grey-darker hover:text-blue lg:underline-animated lg:inline-block lg:mt-0"
                  onClick={this.toggleMenu}
                >
                  Classes
                </Link>
                <Link
                  to="/open-jump"
                  className="flex mt-8 text-grey-darker hover:text-blue lg:underline-animated lg:inline-block lg:mt-0"
                  onClick={this.toggleMenu}
                >
                  Open Jump
                </Link>
                <Link
                  to="/parties"
                  className="flex mt-8 text-grey-darker hover:text-blue lg:underline-animated lg:inline-block lg:mt-0"
                  onClick={this.toggleMenu}
                >
                  Parties
                </Link>
                <OutboundLink
                  href="https://app.jackrabbitclass.com/portal/ppLogin.asp?id=513632"
                  className="lg:px-4 lg:py-2 lg:-mt-1 mt-8 lg:leading-none lg:bg-blue rounded flex lg:inline-block lg:h-8 lg:text-white lg:hover:text-white lg:hover:shadow-md shadow-transition text-grey-darker hover:text-blue no-underline uppercase"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.toggleMenu}
                >
                  Customer Portal
                </OutboundLink>
              </div>
              <div className="absolute mobile-menu lg:hidden py-05">
                <button
                  className="flex items-center mr-2 px-3 py-2 border rounded text-grey-darker border-grey hover:text-blue hover:border-blue-light"
                  onClick={this.toggleMenu}
                >
                  <svg
                    className="fill-current h-3 w-3"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
                </button>
              </div>
            </nav>
          </div>
        </div>
      </>
    )
  }
}
